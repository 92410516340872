import { Link } from 'gatsby'
import { getImage, StaticImage } from 'gatsby-plugin-image'

import videoReviewLogo from '~/assets/images/case-study/ref/video-review-logo.svg?file'
import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import { CommonImageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyClientVideoReview from '~/views/CaseStudies/components/CaseStudyClientVideoReview'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'
import CaseStudyVideoCase from '~/views/CaseStudies/components/CaseStudyVideo'

import * as containerStyles from './RefDental.module.scss'
import useRefDentalStaticQuery from './useRefDentalStaticQuery'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

const quotes = {
  client: {
    name: 'Leon Zinger',
    position: 'Founder at RefDental',
  },
  pm: {
    name: 'Maria',
    position: 'Project Manager at Codica',
  },
}

const previewWownerTitle = 'Intelligent Job Match System'
const previewPmkTitle = 'Online Service Marketplace'

const CaseStudiesRefDental = () => {
  const query = useRefDentalStaticQuery()

  const bannerCase = getImage(fileToImageLikeData(query.bannerCase))
  const clientVideoReview = getImage(
    fileToImageLikeData(query.clientVideoReview),
  )
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const videoCaseImage = getImage(fileToImageLikeData(query.videoCaseImage))

  return (
    <MainLayout isTransparent scrollTop>
      <CaseStudyHead
        title="Dental Imaging Management System"
        maxWidthTitle="553px"
        subtitle="Learn how Codica helped the customer create a dental portal, and streamline the collaboration between the dental imaging center and clinics."
        image={bannerCase}
        classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
        imageAlt="Online dental platform Ref Dental"
        imageTitle="Online dental portal Ref Dental"
        domain="Healthcare"
        location="Israel"
        timeline="January 2021 - present"
        services={
          <>
            <Link to="/services/product-discovery/" key="productKey">
              Product discovery
            </Link>
            , Prototyping,
            <Link key="designKey" to="/services/ui-ux-design/" className="ml5">
              UX/UI design
            </Link>
            , Front-end and Back-end development,
            <Link
              key="mvpDevKey"
              to="/services/mvp-development/"
              className="ml5"
            >
              MVP development services
            </Link>
            ,
            <Link
              to="/services/progressive-web-apps-development/"
              className="ml5"
              key="pwaDevKey"
            >
              PWA development services
            </Link>
          </>
        }
        team="1 Project Manager, 1 UX/UI designer, 1 Backend Developer, 2 Frontend Developers, 1 QA Engineer, 1 DevOps Engineer"
        technologies="React, Rails API, AWS, Gitlab CI, Redis"
      />

      <div className={containerStyles.caseStudy__bgLightGray}>
        <LazyHydrate whenVisible>
          <CaseStudyClientVideoReview
            color="videoReviewRef"
            link="https://www.youtube.com/embed/r1tbx4MEmrY"
            clientImage={clientVideoReview}
            imageTitle="Customer review about online dental platform Ref Dental"
            logo={videoReviewLogo}
            name="Leon Zinger, Founder at RefDental"
            review={[
              {
                item: '“What we liked most about Codica team is their deep understanding of our pain points and requirements. They quickly grasped our business idea and helped us define the basic features for the Ref Dental project. As a result, we could build the referral management software pretty fast and present it to real users.',
              },
              {
                item: 'Later, when we got feedback from early users, Codica team helped us with adapting the dental portal to satisfy the users’ primary needs.”',
              },
            ]}
          />
        </LazyHydrate>
      </div>

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudy__bgDark}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTextLeft={[
          'The customer is an owner of a dental imaging center located in Israel. It works with dental clinics that send their patients to the center for dental imaging and CT/Panoramic/Periapical.',
          <div className="mb-3" key="bgTextLeft" />,
          'The staff was scheduling patients’ appointments in an old-fashioned way by the phone and entering their data in local software installed at the center. In addition, the dental imaging management system was not digitized but paper-based.',
        ]}
        bgTextRight={[
          'So the customer started looking for ways to improve their working protocols. The decision was made to create a dental website that will help the dental imaging center to move to digital referrals management.',
          <div className="mb-3" key="bgTextRight" />,
          'Our collaboration with the customer started with MVP development. After launching the minimum viable product and getting initial feedback from early users, the customer decided to improve the dental imaging management system by adding new functionality.',
        ]}
        sectionBusiness={[
          {
            label:
              'Build an MVP of a dental portal to allow the customer to verify the business idea with minimum investment and effort.',
          },
          {
            label:
              'Create patient referral management software for the dental imaging center to enable the client to save time and money with digitized image management.',
          },
          {
            label:
              'Advance an MVP development project with extra functionality based on real feedback from end-users.',
          },
          {
            label:
              'Make the website for dental practice user-friendly and convenient for Israeli users.',
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Built the primary version of the product with core functionality. By investing in MVP development services, the customer was able to test initial business hypotheses.',
          },
          {
            label:
              'Created a healthcare referral management software for seamless communication between the dental center and clinics. Now clinics can fill in the referral form and send it straight to the dental imaging center for further scheduling. The center no longer needs to schedule appointments for patients imaging via phone.',
          },
          {
            label:
              'After the customer got the feedback from early adopters, we started improving the referral tracking management system. Thus, the decision was made to add a new platform role - a patient and implement the convenient booking system.',
          },
          {
            label:
              'Made the referral management software convenient for Israeli users by providing the solution version in the Hebrew language.',
          },
        ]}
      />

      <div className={containerStyles.caseStudy__bgLightGray}>
        <CaseStudyQuote
          quote={[
            {
              text: '“It was amazing to work on such a project as Ref Dental: we basically witnessed how the product was developed from an MVP to a fully functional solution. As a result, our client (who was also extremely pleasant to work with) received the digitized process of exchanging the dental images between his imaging center itself and the clinics.',
            },
            {
              text: 'And Ref Dental is only getting started - the client now receives feature requests from real users, and we’re here to help to implement them to further enhance the dental imaging management system!”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/PM-Maria.png"
              alt={`${quotes.pm.name}, ${quotes.pm.position} at Codica`}
              title={`${quotes.pm.name}, ${quotes.pm.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.pm.name}
          position={quotes.pm.position}
        />
      </div>

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudy__bgDark}
        title="Do you need a reliable team to build your custom web product?"
        description="We apply best practices to help you save costs and launch quickly."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          image={solutionDeliveredOne}
          title="Online dental portal for the dental imaging center"
          description="For the convenience of both parties, the referral tracking management system has an intuitive dashboard. The statistic shows the number of clinics and referral forms, both new and completed. Here the dental imaging center can add a new form with one click and send dental images to clinics via these forms. Clinics, in their turn, can check the current status of referral forms, whether it is pending or completed."
          alt="Online platform for the dental imaging center"
          imageTitle="Online portal Ref Dental for the dental imaging center"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredTwo}
          title="Referral tracking management system"
          description="Initially, clinics used to send patients to the dental imaging center with a long paper-based form. We digitized the existing form and optimized it for all screen sizes and resolutions. With the healthcare referral management software, clinics can fill in the form they are used to, but in a faster and more convenient way, and send it to the dental center. Furthermore, the referral form contains a Messaging section, where the assigned clinics can privately exchange messages with the center in the form of a chat."
          alt="Simple referral form management"
          imageTitle="Convenient referral form management"
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="Progressive Web Application"
          description={[
            'The customer found it crucial to enable users to access the dental imaging management system with one click. For this purpose, we turned the website into a progressive web application. PWAs work as native mobile apps and have the “Add to the Home Screen” feature, which makes using the app more convenient.',
            <div className="mb-2" key="descr" />,
            'Synchronization of the data between the website and the PWA offers an enhanced user experience. Currently, we are working on the push notifications functionality which will inform both the center and clinics about all interactions with the online dental platform.',
          ]}
          alt="PWA for the dental portal"
          imageTitle="Progressive web application for the dental portal"
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredFour}
          title="Booking management system"
          description={
            <>
              Currently, our
              <Link
                to="/services/custom-software-development/"
                className="mx5"
                key="keyCaseSolutionCustomSoft"
              >
                custom software developers
              </Link>
              are working on implementing the patient scheduling system. The
              idea is to allow patients or the center to schedule the imaging
              appointment online. We plan to add a calendar functionality where
              a patient can choose the preferred date and time slot. This way,
              we will reduce the number of phone calls and time spent on them.
              <div className="mb-2" key="descr" />
              Consequently, we will both provide a better and simpler experience
              for all users, and help the dental center save costs on processing
              the calls with convenient patient scheduling software.
            </>
          }
          alt="Intuitive booking management system"
          imageTitle="Convenient booking management system"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="Localization for Israeli users"
          description={[
            'We used Material UI and React-intl for localization purposes. Material UI supports right-to-left languages such as Hebrew, so we used it to change the direction of the web page components.',
            <div className="mb-2" key="descr" />,
            'Apart from that, React-intl helped us translate the web content into Hebrew and customize the dental imaging management system for the Israeli market.',
          ]}
          alt="Online dental platform for Israel users"
          imageTitle="Online dental portal for Israel"
        />
      </div>

      <LazyHydrate whenVisible>
        <CaseStudyVideoCase
          title="How it works"
          description="This video shows how a dental clinic representative can fill in the referral form and send it straight to the dental imaging center for further scheduling. You can see all the fields that need to be filled in in order to proceed with the appointment."
          ringsClass={containerStyles.caseStudyVideo__ring}
          image={videoCaseImage}
          videoLink="https://www.youtube.com/embed/igJfal7M3Ac"
        />
      </LazyHydrate>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudyTech
          technologies={[
            { label: 'React', icon: 'react' },
            { label: 'Rails API', icon: 'ror' },
            { label: 'AWS', icon: 'aws' },
            { label: 'Redis', icon: 'redis' },
            { label: 'Gitlab CI', icon: 'gitlab' },
          ]}
          integrations={[{ label: 'Whatsapp API', icon: 'whatsapp' }]}
        />
      </div>

      <div className={containerStyles.caseStudyQuote__bgQuote}>
        <CaseStudyQuote
          isBottom
          quote={[
            {
              text: '“We are happy to have Codica as our technical partner in web app development services. They take the project as seriously as we do and really care about its success. Together we are working on improving the Ref Dental platform to make it helpful for our dental imaging center, clinics, and patients.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/clients/client-ref.png"
              alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
              title={`${quotes.client.name}, ${quotes.client.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.client.name}
          position={quotes.client.position}
        />
      </div>

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s build a great custom product together!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewWownerTitle}
              color="wownerColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/previews/case-wowner.png"
                  alt={`Case study: ${previewWownerTitle} | Codica`}
                  title={`Case study: ${previewWownerTitle}`}
                  placeholder="blurred"
                  objectFit="fill"
                  width={350}
                />
              }
              url="intelligent-job-matching-service"
            />
            <CaseStudyPreview
              title={previewPmkTitle}
              color="pmkColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/previews/case-pmk.png"
                  alt={`Case study: ${previewPmkTitle} | Codica`}
                  title={`Case study: ${previewPmkTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="kids-activities-service-marketplace"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesRefDental
