// extracted by mini-css-extract-plugin
export var caseContactFormSection = "b4_kr";
export var caseStudyBackground__lineColor = "b4_kp";
export var caseStudyHead__imageWrapper = "b4_kk";
export var caseStudyProjectsSection = "b4_ks";
export var caseStudyQuote__bgQuote = "b4_nv";
export var caseStudyQuote__bgRing = "b4_km";
export var caseStudyVideo__ring = "b4_kv";
export var caseStudy__bgDark = "b4_kh";
export var caseStudy__bgLight = "b4_kg";
export var caseStudy__bgLightGray = "b4_ml";